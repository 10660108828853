/*******************************************************************************
* General
*******************************************************************************/
.site-main{
    margin-top: 100px;
    min-height: 600px;
    position: relative;
    z-index: 1;
}
.no-scrollbar {
    overflow: hidden;
}
.video-banner-section{
    img{
        width: 100%;
    }
}
.red-color{
    color:$brand-color;
}
.welcome-screen-wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    text-align: center;
    z-index: 999;
}
.welcome-screen{
    height: 100%;
    img{
        max-width: 80%;
    }
}
@include media-breakpoint-down(md){
    .site-main {
        margin-top: 65px;
    }
}