/*******************************************************************************
* Single-Col Elements
*******************************************************************************/
.single-slide-image{
    margin: 0 auto;
    margin-bottom: 35px;        
}
.single-column-slider{
    margin-bottom:100px;
    .owl-carousel {
        .owl-nav{
            button.owl-prev, button.owl-next{
                top:44.5%;
            }
        }
        .owl-dots{
            bottom: -41px;
        }
    }
}
.single-column-content {
    padding-top: 71px;
    padding-bottom: 37px;
    h4{
        margin-bottom: 31px;
    }
}

@media (max-width:1400px){
    .single-slide-image {                
        max-width: 800px;
    }
}

@include media-breakpoint-down(lg){
    .single-slide-image {                
        max-width: 70%;
    }
}
@include media-breakpoint-down(md){
    .single-column-slider{
        margin-bottom:100px;
        .owl-carousel {        
            .owl-dots{
                bottom: -30px;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    .single-column-content{
        padding-top: 30px;
    }
    .single-slide-image {                
        max-width: 80%;
    }
}
@include media-breakpoint-down(xs){
    .single-slide-image {                
        max-width: 100%;
    }
    .single-column-slider{
        margin-bottom:20px;
        .owl-carousel {        
            .owl-dots{
                bottom: -20px;
            }
        }
    }
}
