//****************************************************************************
//  Bootsrap variables overrides
//***************************************************************************/
$font-family-sans-serif:      'Open Sans', Arial, Helvetica, sans-serif;
$font-primary:                $font-family-sans-serif;
$font-secondary:              'Open Sans Condensed', Arial, Helvetica, sans-serif;
$black:                       #000000;
$white:                       #ffffff;
$body-color:                  $white;
$btn-box-shadow:              none;

//****************************************************************************
//  Custom variables 
//***************************************************************************/
$brand-color:                 #cd0022;
$brand-color-hover:           $white;
$brand-color-primary:         $white;
$brand-color-primary-hover:   $brand-color;

$brand-color-secondary:       #4d4d4d;
$brand-color-secondary-hover: $brand-color;

$brand-color-gray:            #8c9192;
$brand-color-gray-hover:      #b5bbbc;
$brand-color-separator:       #323232;
$table-hover-bg:              #fcf2e7;
$brand-dark-gray:             #101010;
$input-color:                 $body-color;

//****************************************************************************
//  Font Weight
//***************************************************************************/
$light-weigth:                 300;
$regular-weigth:               400;
$semi-bold-weigth:             600;
$bold-weigth:                  700;
