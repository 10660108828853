/*******************************************************************************
* Image with content Elements
*******************************************************************************/
.image-with-content{
    padding: 55px 0;
    &.bottom-seprator{
        padding: 103px 0;
    }
}
.content-block{    
    > img{
        max-width: 220px;
        margin-bottom: 28px;
    }
    ul{
        margin-bottom: 33px;
    }
    .mendate-text{
        position:relative;
        &:before{
            content:'*';
            position: absolute;
            left:0;
            top:-5px;  
            font-size: 12px;
        }
        small{
            padding-left: 10px;
            font-size: 12px;
            line-height: 16px;        
            @include inline-block;
            max-width: 320px;
        }
    }        
}
.bottom-seprator{
    border-bottom: 1px solid $brand-color-separator;
}
.content-highlight{
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(center);
    text-align: center;
    height: 100%;
    h4{
        margin-bottom: 30px;
        font-weight: $semi-bold-weigth;
    }
}
.vertical-image-block{
    padding-bottom:142px;
    .content-block{
        padding-top: 44px;
    }    
    .image-block{
        margin-top: 15px;
        margin-left:100px;
    }
    .content-highlight{        
        display: block;
        margin-top: 48px;
        h4{
            margin-bottom: 30px;
        }
    }
}
@media (min-width:1600px){
    .left-position{
        position: relative;
        left: 0; 
        top: 0; 
        width: 100%;
        margin-bottom: 30px;
    }
    .right-position{
        left: 12%;
        top: 3%;
        position: relative;
    }   
}

@include media-breakpoint-between(sm,md){
    .image-block {
        margin-top: 50px;
    }
}
@include media-breakpoint-down(lg){
    .vertical-image-block{
        padding-bottom:100px;
    }
    .right-position{        
        max-width: 331px;
        margin-left: auto;
        margin-top: 50px;
    } 
}
@include media-breakpoint-down(md){
    .image-with-content{        
        &.bottom-seprator{
            padding: 60px 0;
        }
    }
    .vertical-image-block{
        .content-block{
            padding-top: 0px;
        }
        .image-block {            
            margin: 0 auto;
            margin-left:50px; 
            max-width: 250px;
        } 
        .content-highlight{
            h4 {
                br{
                    display: none;
                }
            }
        }
    }
} 
@include media-breakpoint-down(sm){      
    .image-with-content{        
        &.bottom-seprator{
            padding: 50px 0;
        }
    }
    .vertical-image-block{
        .image-block {            
            margin: 0 auto;
            max-width: 150px;
        }        
    }    
    .content-block{ 
        .mendate-text{    
            &:before{
                top:-1px;
            }
            small{
                br{
                    display:none;
                }
            }
        }        
        > img {
            max-width: 110px;
            margin-bottom: 15px;
        }
    }
    .image-block {
        &.right-position{
            max-width: 250px;
            margin: 50px auto;
        }        
    }    
}