/*******************************************************************************
* Footer
*******************************************************************************/
footer.page{
    background-color: inherit;
    color: $brand-color-primary;
    font-family: $font-primary;
    h5{
        color: inherit;
        margin-bottom: 10px;
        font-weight: $semi-bold-weigth;
    }
    p{
        margin-bottom: 10px;
    }
    a{
        color: inherit;
        &:hover{
            color: $brand-color;
        }
    }
}
.main-footer-wrap{
    padding: 45px 0 28px;
    background-color: $brand-dark-gray;
}
.main-footer{
    li{
        width: 100%;
        @include inline-block;
        span{
            min-width: 96px;
            @include inline-block;
        }
        label{
            margin: 0;
        }
        &:after{
            display: none;
        }
        a{
            text-decoration: underline;
        }
    }

}
.social-block {
    @include flexbox;
    padding: 9px 0;
    a{
        width: 42px;
        height: 42px;
        text-align: center;
        background-color: $brand-color;
        border-radius: 50%;
        margin: 5px 15px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        &.facebook {
            background-color: #3e5b98;
        }
        &.youtube {
            background-color: #e02a20;
        }
        &.instagram {
            background-color: #405de6;
        }
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            color: $brand-color-primary;

        }
        .fa{
            font-size: 23px;
            color: $brand-color-primary;
        }
    }
}
.bottom-footer-wrap{
    padding: 45px 0 28px;
}
.additional-menu{
    li{
        margin: 0 25px;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.goto-top{
    border-radius: 50%;
    background-color: $brand-color-secondary;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 30px;
    bottom: 20px;
    text-align: center;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 0 9px rgba(0,0,0,0.3);
    &.visible {
        opacity: 1;
        z-index: 9;
    }
    img{
        margin: 10px;
        height: 20px;
        @include rotate(-90deg);
    }
    .fa{
        font-size: 26px;
        margin-top: 6px;
        color: $brand-color-primary;
    }
    &:hover{
        background-color: $brand-color-secondary-hover;
    }

}
@include media-breakpoint-between(md,lg){
    .additional-menu {
        padding-right: 70px;
    }
    .bottom-footer-wrap{
        padding: 35px 0 14px;
    }
}
@include media-breakpoint-only(md){
    .main-footer{
        li {
            font-size: 16px;
            line-height: 28px;
            &:nth-child(3){
                &:after{
                    display: none;
                }
            }
            &:after{
                height: 16px;
                top: 4px;
            }
        }
    }
}
@include media-breakpoint-up(xs){
    .additional-menu ul{
        justify-content: flex-end;
    }
}
@include media-breakpoint-down(sm){
    .main-footer-wrap {
        padding: 26px 0 22px;
    }
    footer.page{
        h5{
            margin-bottom: 9px;
        }
        p{
            margin-bottom: 10px;
        }
    }
    .main-footer{
        ul {
            margin: 3px 0 42px;
        }
        li{
            width: 100%;
            span {
                min-width: 65px;
            }
        }
    }
    .social-block {
        padding: 2px 0;
        a {
            margin: 5px 14px;
        }
    }
    .bottom-footer-wrap {
        padding: 25px 0 8px;
    }
    .goto-top{
        right: 16px;
        bottom: 15px;
    }
}
@include media-breakpoint-down(sm){
    .bottom-footer-wrap {
        padding: 26px 0 16px;
    }
    .additional-menu{
        ul{
            &.d-flex {
                display: inline-block !important;
                margin-bottom: 21px;
            }
            li{
                margin: 0 0 12px;
            }

        }
    }
}