/*******************************************************************************
* CTA Elements
*******************************************************************************/
.cta-elemnt{
    background-color: $brand-dark-gray;
    margin-top: 98px;
    padding: 71px 0;
    h4{
        font-weight:$semi-bold-weigth;
        margin-bottom: 34px;
    }
}
.cta-btn-block{
    margin-top: 30px;
    max-width: 344px;
    margin: 0 auto;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);            
    a{
        display: inline-block;                
    }
}
@include media-breakpoint-down(sm){
    .cta-btn-block{        
        max-width: 240px;
    }
    
}
@include media-breakpoint-down(xs){
    .cta-elemnt{
        margin-top: 60px;
        padding: 40px 0;
    }
    .cta-btn-block{        
        a{         
            img{
                max-width: 110px;
            }
        }
    }
}
