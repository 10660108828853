/*******************************************************************************
* Contact Info Section
*******************************************************************************/
.contact-info-section{
    padding: 71px 0 18px;
    border-top: 1px solid $brand-color-separator;
    h3{
        color: inherit;
        line-height: 32px;
        text-align: center;
    }
}
.contact-info-block{
    padding: 20px 0;
    li{
        margin: 15px 15px 25px;
        a{
            position: relative;
            padding-left: 68px;
            @extend %fontsize24;
            color: $brand-color;
        }
        &:after{
            display: none;
        }
    }
}
.c-icon{
    @include vertical-align($position: absolute);
    left: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: $brand-color;
    border-radius: 50%;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    .fa{
        font-size: 24px;
        color: $brand-color-primary;
        &.fa-envelope{
            font-size: 20px;
        }
    }
}
@include media-breakpoint-up(sm){
    .contact-info-section{
        padding: 71px 0 18px;
        text-align: center;
    }
    .contact-info-block{
        padding: 20px 0;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        li{
            margin: 15px 41px;
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }

        }
    }
}
@include media-breakpoint-down(xs){
    .contact-info-section{        
        h3{
            br{
                display: none;
            }
            line-height: 22px;
        }
    }
    .contact-info-block {
        li {
            display: inline-block;
        }
    }
}