/*******************************************************************************
* Button
*******************************************************************************/
.btn{
    padding: 9px 25px;
    border-radius: 3px;
    margin: 0;
    outline: 0;
    box-shadow: none;
    white-space: normal;
    color: $white;
    font-weight: $semi-bold-weigth;
    font-family: $font-primary;
    border:none;
    background-color: $brand-color;    
    @include transition(all,0.2s,ease,0s);
    @include inline-block();
    @extend %fontsize16;
    &:hover,&:focus, &:active{
        background-color: $brand-color-hover;
        color: $white;
        outline: 0;
        box-shadow: none;
    }
}
.btn-outline{
    background-color: transparent;
    color: $white; 
        
    border:2px solid $white;
    border-radius: 60px;
    padding: 7px 28px;
    &:hover, &:focus{
        background-color: $brand-color;        
        border-color: $brand-color;     
    }
}
button:focus {
    outline: none;
}
@include media-breakpoint-down(sm){
    .btn{
        min-width: 1px;
        font-size: 13px;
        padding: 8px 15px;
    } 
}