/*******************************************************************************
* All Slider
*******************************************************************************/
.owl-carousel{
    .owl-item{
        text-align: center;
        img{
            width:auto;
            margin: 0 auto;
        }
        .slide-content{
            p{
                img{
                    margin-top: -4px;
                }
            }
            img{                
                display: inline;
            }  
        } 
    }
    .owl-nav{
        span{
            display: none;
        }
        button{
            &.owl-prev, &.owl-next{
                width: 28px;
                height: 28px;
                border-radius: 0;
                position: absolute;
                top: 41%;
                right: -75px;
                margin-top: -12px;
                border-style: solid;
                border-width: 0 2px 2px 0;
                border-color: transparent $white $white;
                @include inline-block;                
                padding: 7px;
                @include rotate(-45deg);
                @include transition(all, 0.2s, ease-in-out, 0s);
                outline: none;
            }
            &.owl-prev{
                left: -75px;
                right: auto;
                @include rotate(132deg);
            }
        }
    }
    .owl-dots {
        text-align: center;
        position: absolute;
        bottom: -82px;
        @include horizontal-align();
        button{
            width: auto;
        }
        .owl-dot {
            @include inline-block;
            span {
                width: 6px;
                height: 6px;
                margin: 8px 5px;
                background: #ccc;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity .2s ease;
                border-radius: 30px;
            }
            &.active{
                span{
                    background: $brand-color;
                }
            }
        }
    }
}
.two-column-carousel{
    margin-bottom: 55px;
    padding-top:18px;
    h2{
        margin-bottom: 7px;
    }
}
.column-slider-init{
    margin-top: 55px;
}
.slide-image{        
    max-width: 90%;    
    margin:0 auto;
    margin-bottom: 30px;
}  
.slide-content{
    text-align: center;
    padding:0 15px;
    img{
        display: inline;
        vertical-align: top;
    }
    p{
        margin-bottom: 0;
        @include inline-block;        
        vertical-align: top;
        margin-top: 3px;
        padding-left: 10px;
    }
}
@media (max-width:1400px){
    .owl-carousel{        
        .owl-nav{            
            button{
                &.owl-prev, &.owl-next{
                    right: 10px;
                    width: 25px;
                    height: 25px;
                }
                &.owl-prev{
                    left:10px
                }
            }
        }
    }
}
@include media-breakpoint-down(lg){
    .slide-image {
        max-width: 80%;
    }
}
@include media-breakpoint-down(md){ 
    .owl-carousel{        
        .owl-dots{
            bottom: -52px;
        }
    }
    .slide-image { 
        margin-bottom: 15px;
    }
}  
@include media-breakpoint-down(sm){ 
    .slide-image {
        max-width: 100%;
    }
    .two-column-carousel{
        margin-bottom: 20px;
    }
    .owl-carousel{
        .owl-item{ 
            .slide-content{
                img{
                    max-width: 30px;
                }
            }
        }    
        .owl-dots {
            right: auto;
            left: 50%;
            bottom:-40px;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
             .owl-dot{
                span{
                    margin: 8px 3px;
                }
             }
        }
        .owl-nav {
            display: none;
        }
    }
}