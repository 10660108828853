/*******************************************************************************
* Full Width Video Section
*******************************************************************************/
.video-banner-section{
    iframe, .video-player{
        width: auto;
        height: calc(100vh - 100px);
        max-height: 1080px;
        position: relative;
    }
    .unmute-btn{
        position: absolute;
        top: 17px;
        left: 15px;
        background: rgba($black,0.4);
        color: $brand-color-primary;
        border: 2px solid rgba($brand-color-separator,0.8);
        font-size: 22px;
        line-height: 24px;
        border-radius: 50%;
        padding: 8px 10px;
        width: 50px;
        height: 50px;
        text-align: center;
        .fa{
            :before {
                @include transition(all, 0.2s, ease, 0s);
            }   
        }
        &.un--muted{
            .fa-volume-off:before {
                content: "\f028";
            }
        }
    }
}
@include media-breakpoint-down(lg){
    .video-banner-section{
        .unmute-btn{
            top: 17px;
            left: 12px;
            font-size: 16px;
            width: 37px;
            height: 37px;
            padding: 0;
        }
    }
}
@include media-breakpoint-down(md){
    .site-main {
        margin-top: 65px;
    }
}
@include media-breakpoint-between(md,lg){
    .video-banner-section iframe,
    .video-banner-section .video-player{
        height: 460px;
    }
}
@include media-breakpoint-only(sm){
    .video-banner-section iframe,
    .video-banner-section .video-player{
        height: 290px;
    }
}
@include media-breakpoint-down(xs){
    .video-banner-section iframe,
    .video-banner-section .video-player{
        height: 300px;
    }
}