/*******************************************************************************
* Tab With Table Section
*******************************************************************************/
.tab-table-wrap{
    padding: 46px 0 47px;
    .nav-tabs{
        border-bottom: 0;
        margin:0 -20px 30px;
        li{
            padding: 0 20px;
            width: 50%;
            &:after{
                display: none;
            }
            a{
                border: 2px solid $brand-color-gray;
                padding: 13px;
                text-align: center;
                @include inline-block;
                width: 100%;
                position: relative;
                color: $brand-color-gray;
                font-weight: $semi-bold-weigth;
                @extend %fontsize20;
                &:after{
                    content: '';
                    width: 0; 
                    height: 0; 
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid transparent;
                    position: absolute;
                    @include transition(all, 0.2s, ease, 0s);
                }
                &.active{
                    color: $brand-color;
                    border: 2px solid $brand-color;
                    &:after{
                        border-top: 10px solid $brand-color;
                    }
                }
            }
        }
    }
    .tab-content{
        padding: 30px 0;
    }
}
.specification-table {
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    .specification-sticky-col {
        text-align: left;
    }
    thead{
        th {
            border: none;
            padding: 10px;
            text-align: center;
        }  
    }    
    tbody {
        td {
            padding: 20px 31px;
            text-align: center;
        }
    }
}
.specification-wrapper {
    position: relative;
    .table-striped {
        thead {
            tr{
                th{
                    min-width: 290px;
                    img{
                        height: 30px;
                        margin-bottom: 30px;
                    }
                    &:first-child{
                        opacity: 0;
                    }
                }
            }
        }
        tbody {
            tr{
                &:nth-of-type(odd){
                    background-color: #1c1c1c;
                }
            }
        }
    }
}
.specification-table-notes{
    border-top: 1px solid $brand-color-separator;
    padding: 36px 0;
    margin: 20px 0;
    @include flexbox;
    @include align-items(center);
    li{
        @include inline-block;
        vertical-align: middle;
        margin-right: 65px;
        &:last-child{
            margin-right: 0;
        }
        &:after{
            display: none;
        }
        img{
            margin-right: 15px;
            margin-top: -5px;
        }
    }
}
@media (min-width:1200px) and (max-width: 1400px){
    .tab-table-wrap{
        .nav-tabs{
            margin: 0 -15px 30px;
        }
    }
}
@include media-breakpoint-down(lg){
    .tab-table-wrap {
        .nav-tabs {
            margin: 0 -15px 22px;
        }
    }
    .specification-wrapper {
        .table-striped {
            thead {
                tr{
                    th{
                        min-width: 1px;
                        img{
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
    .specification-table {
        tbody {
            td {
                img{
                    max-height: 28px;
                }
            }
        }
    }
}
@media (max-width:1400px){
    .tab-table-wrap{
        .nav-tabs{
            margin: 0 0px 30px;
        }
    }
}
@include media-breakpoint-down(lg){
    .tab-table-wrap{
        padding: 46px 0 0;
        .tab-content{
            padding: 30px 0 0;
        }
    }  
}
@include media-breakpoint-down(md){
    .specification-table {
        tbody {
            td {
                padding: 15px;
            }
        }
    }
}
@include media-breakpoint-up(md){
    .specification-table .specification-sticky-col {
        position: relative;
        width: auto;
    }
    .specification-table {
        thead th,
        tbody td {
            white-space: nowrap;
        }  
    }
}
@include media-breakpoint-down(sm){
    .specification-scroller {
        margin-left: 150px;
        overflow-x: scroll;
        overflow-y: visible;
        padding-bottom: 5px;
        width: calc(100% - 150px);
    }
    .specification-wrapper .table-striped thead tr th img {
        margin-bottom: 15px;
    }
    .specification-table {
        thead th,
        tbody td {
            height: 53px;
        }  
        tbody {
            td {
                border-right:1px solid $brand-color-separator;
                padding: 9px 15px;
            }
        }
        .specification-sticky-col {
            left: 0;
            position: absolute;
            top: auto;
            width: 166px;
            text-align: left;
            font-weight: $semi-bold-weigth;
        }
    }
    .tab-table-wrap .tab-content{
        margin: 0 -15px;
        padding: 0;
        overflow-x: hidden;
    }
    .specification-wrapper .table-striped tbody tr:nth-of-type(odd) td:first-child {
        background-color: #1c1c1c;
    }
    .specification-table-notes {
        padding: 25px 15px;
        margin: 0 0;
        li{
            margin-right: 45px;
        }
    }
}
@include media-breakpoint-up(sm){
    .tab-table-wrap{
        .nav-tabs{
            li{
                &.dropdown{
                    display: none;
                }
                a{
                    &:after{
                        bottom: -10px;
                        @include horizontal-align();
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xs){    
    .custom-dropdown{ 
        .selected{
            display: none;
        }
    }
}
@include media-breakpoint-down(xs){
    .custom-dropdown{ 
        .selected{
            display: block;
            a{
                background: transparent;
                display: block;
                border: 2px solid $brand-color;
                width: 100%;
                text-decoration: none;
                color: $brand-color;
                font-size: 15px;
                line-height: 28px;
                padding: 5px 17px 7px;
                font-weight: $semi-bold-weigth;
                margin-bottom: 30px;
                position: relative;
                &:after{
                    content: "\f0d7";
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    position: absolute;
                    right: 15px;
                    top: 13px;
                }
                span{
                    cursor:pointer; 
                    display:block; 
                    &.value{
                        display: none;
                    }
                }
            }
        }
        .options{
            position:relative; 
            background-color: $white;
            display:none;
            list-style:none;        
            position:absolute; 
            left: 15px;
            top: 93px;
            width: calc(100% - 30px);
            z-index: 9;
            span{
                &.value{
                    display: none;
                }
            }
            li{
                padding: 0;
                width: 100%;
                a{
                    display:block;
                    text-decoration:none;      
                    border: 0;
                    &.active{
                        color:$brand-color;
                        border-color:$white;
                        &:after{
                            display: none;
                        }
                    }  
                }
            }
        }
    }                        

    .specification-scroller {
        margin-left: 166px;
        width: calc(100% - 166px);
    }
    .specification-table {
        width: 380px;
        thead th,
        tbody td {
            height: 53px;
        }  
        tr{
            position: relative;
        }
    }
    .specification-table-notes {
        li{
            margin-right: 26px;
            img{
                margin-right: 7px;
                margin-top: -3px;
                max-height: 25px;
            }
        }
    }
    .tab-table-wrap{
        padding: 50px 0 0px;
        .nav-tabs{
            li{
                &.hide-mobile{
                    display: none;
                }
                &.dropdown{
                    width: 100%;
                    padding: 0;
                }
                a{
                    text-align: left;
                    font-size: 15px;
                    line-height: 28px;
                    padding: 5px 17px 7px;
                    &:after{
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid transparent;
                        top: 17px;
                        right: 20px;
                    }
                    &.dropdown-toggle{
                        border-color: $brand-color;
                        color: $brand-color;
                        &:after{
                            border-top: 6px solid $brand-color;
                        }
                    }
                }
            }
            .nav-link{
                border-radius: 0;
            }
            .nav-link.active,
            .nav-item.show .nav-link {
                color: $brand-color;
                background-color: transparent;
            }
            .dropdown-menu {
                margin-top: -2px;
                width: 100%;
                padding: 0;
                background-color: $white;
                border-color: transparent !important;
                border-radius: 0;
                a{
                    border-color: transparent !important;
                }
                .dropdown-item{
                    &:after{
                        border-top: 6px solid transparent !important;
                    }
                    &.active, &:active {
                        color: $brand-color-gray;
                        background-color: transparent;
                        border-color: 2px solid transparent;
                    }    
                }
            }
        }
    }
}