/*******************************************************************************
* Content Section
*******************************************************************************/
.content-section{
    padding-top: 71px;
    padding-bottom: 54px;    
    h4{
        line-height: 28px;        
    }
}
.headline-block{
    max-width: 1150px;
    margin: 0 auto;
}
main{
    ul{
        list-style: none;
        padding-left:15px;
        li{
            position: relative;
            &:after{
                content:'';
                position: absolute;
                left: -14px;
                top: 10px;
                width: 3px;
                height: 3px;                
                border-radius:50%;                
                background-color: $white;
            }
        }
    }
    ol{
        margin: 15px 0 35px;
        counter-reset: my-badass-counter;
        li{
            padding-left: 52px;
            margin-bottom: 15px;
            list-style-type: none;
            position: relative;
            &:before {
                counter-increment: my-badass-counter;
                content: counter(my-badass-counter, upper-roman) ".";
                left: 1px;
                position: absolute;
                top: 0;
                font-weight: $semi-bold-weigth;
            }
        }
    }    
}
.full-bg-element{
    position:relative;    
    margin: 0 auto;
    margin-top: 100px;
    max-width: 1600px;
    img{
        width:100%;
    }
    p{
        position: absolute;
        left: 30px;
        bottom: 30px;
        color:$white;
        font-weight: $semi-bold-weigth;
        margin-bottom: 0;
    }
}

.AnimatesOnLoad{position:relative;top:80px;visibility:hidden;}
.AnimatesOnLoad div.divImage{visibility:hidden;}
.AnimatesOnLoad div.divTextImage{visibility:hidden;}

@include media-breakpoint-up(xl){
    .content-section{
        h1{
            line-height: 46px;
        }
    }
}
@include media-breakpoint-only(lg){
    .content-section {
        padding: 80px 0;
        padding-bottom: 60px;
    }
}
@include media-breakpoint-only(md){
    main{
        ol{
            margin: 15px 0 28px;
            li{
                padding-left: 42px;
                margin-bottom: 15px;
            }
        }
    }
}
@include media-breakpoint-down(md){
    .content-section{
        padding: 60px 0;
        br{
            display: none;
        }
    }
}
@include media-breakpoint-down(sm) {
    .full-bg-element{
        margin-top: 30px;
    }
    .content-section{
        h4{
            br{
                display: none;
            }
        }
    }
    main{
        ul{
            li{
                padding-left:0;
                &:before{              
                    top:7px;
                }
            }
        }
        ol{
            margin: 12px 0 25px;
            li{
                padding-left: 35px;
                margin-bottom: 12px;
            }
        }
    }
}
@include media-breakpoint-down(xs){
    .full-bg-element{                
        p{
            left: 15px;
            bottom: 15px;            
        }
    }
    .content-section{
        padding: 40px 0;    
    }
}