/****************************************************************************
 * Fonts
 ***************************************************************************/

// Open Sans Light
@font-face {
    font-family: 'Open Sans';
    src: url('../font/Open-Sans-Light.eot');
    src: url('../font/Open-Sans-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-Light.woff2') format('woff2'),
        url('../font/Open-Sans-Light.woff') format('woff'),
        url('../font/Open-Sans-Light.ttf') format('truetype'),
        url('../font/Open-Sans-Light.svg#Open-Sans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

// Open Sans Regular 
@font-face {
    font-family:'Open Sans';
    src: url('../font/Open-Sans-Regular.eot');
    src: url('../font/Open-Sans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-Regular.woff2') format('woff2'),
        url('../font/Open-Sans-Regular.woff') format('woff'),
        url('../font/Open-Sans-Regular.svg#Open-Sans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

// Open Sans Italic
@font-face {
    font-family:'Open Sans';
    src: url('../font/Open-Sans-Italic.eot');
    src: url('../font/Open-Sans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-Italic.woff2') format('woff2'),
        url('../font/Open-Sans-Italic.woff') format('woff'),
        url('../font/Open-Sans-Italic.ttf') format('truetype'),
        url('../font/Open-Sans-Italic.svg#Open-Sans-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
}

// Open Sans SemiBold
@font-face {
    font-family:'Open Sans';
    src: url('../font/Open-Sans-SemiBold.eot');
    src: url('../font/Open-Sans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-SemiBold.woff2') format('woff2'),
        url('../font/Open-Sans-SemiBold.woff') format('woff'),
        url('../font/Open-Sans-SemiBold.svg#Open-Sans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

// Open Sans SemiBold Italic
@font-face {
    font-family: 'Open Sans';
    src: url('../font/Open-Sans-SemiBold-Italic.eot');
    src: url('../font/Open-Sans-SemiBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-SemiBold-Italic.woff2') format('woff2'),
        url('../font/Open-Sans-SemiBold-Italic.woff') format('woff'),
        url('../font/Open-Sans-SemiBold-Italic.ttf') format('truetype'),
        url('../font/Open-Sans-SemiBold-Italic.svg#Open-Sans-SemiBold-Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

// Open Sans Bold
@font-face {
    font-family:'Open Sans';
    src: url('../font/Open-Sans-Bold.eot');
    src: url('../font/Open-Sans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-Bold.woff2') format('woff2'),
        url('../font/Open-Sans-Bold.woff') format('woff'),
        url('../font/Open-Sans-Bold.svg#Open-Sans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

// Open Sans Condensed Light
@font-face {
    font-family: 'Open Sans Condensed';
    src: url('../font/Open-Sans-Condlight.eot');
    src: url('../font/Open-Sans-Condlight.eot?#iefix') format('embedded-opentype'),
        url('../font/Open-Sans-Condlight.woff2') format('woff2'),
        url('../font/Open-Sans-Condlight.woff') format('woff'),
        url('../font/Open-Sans-Condlight.ttf') format('truetype'),
        url('../font/Open-Sans-Condlight.svg#Open-Sans-Condlight') format('svg');
    font-weight: 300;
    font-style: normal;
}
/****************************************************************************
  Body
 ***************************************************************************/
body {
    @extend %fontsize16;
    background-color: $black;
    font-weight: $light-weigth;
}
.container{
    max-width: 1250px;
}
p {
    font-weight: $light-weigth;
    margin-bottom: 15px;
}
img{
    max-width: 100%;
    height: auto;
}
figure{
    margin: 0 0 15px;
}
ul{
    list-style-type: none;
    padding: 0;
}
/****************************************************************************
  Links
 ***************************************************************************/
a {
    color: inherit;
    outline: none;
    @include transition(all,0.2s,ease,0s);
    font-family: $font-primary;
    &:hover, &:focus {
        color: inherit;
        text-decoration: none;
    }
    span{
        @include transition(all,0.2s,ease,0s);
    }
}

strong, b{
    font-weight: $semi-bold-weigth;
}

::-moz-selection { /* Code for Firefox */
    color: $white;
    background: $brand-color;
}

::selection {
    color: $white;
    background: $brand-color;
}
/****************************************************************************
  Headings
 ***************************************************************************/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-primary;
    color: inherit;
    font-weight: $light-weigth;
    margin-bottom: 24px;
}
h1{
    @extend %fontsize48;
    font-style: italic;
    color: $brand-color-gray;
}
h2{
    @extend %fontsize40;
    font-weight: $regular-weigth;
    font-style: italic;
    color: $brand-color-gray;
}
h3{
    @extend %fontsize24;
    font-weight: $light-weigth;
}
h4{
    @extend %fontsize20;
}
h5{
    @extend %fontsize16;
}