//****************************************************************************
//  General extensions
//***************************************************************************/

%inherit_textstyles {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    text-transform: inherit;
    text-align: inherit;
    font-style: inherit;
}

//****************************************************************************
//  Font sizes extensions
//***************************************************************************/
%fontsize48 {
    font-size: 48px;
    line-height: 56px;
}

%fontsize40 {
    font-size: 40px;
    line-height: 48px;
}

%fontsize36 {
    font-size: 36px;
    line-height: 40px;
}

%fontsize30 {
    font-size: 30px;
    line-height: 36px;
}

%fontsize24 {
    font-size: 24px;
    line-height: 36px;
}

%fontsize22 {
    font-size: 22px;
    line-height: 22px;
}

%fontsize20 {
    font-size: 20px;
    line-height: 31px;
}

%fontsize18 {
    font-size: 18px;
    line-height: 28px;
}

%fontsize16 {
    font-size: 16px;
    line-height: 24px;
}

%fontsize15 {
    font-size: 15px;
    line-height: 21px;
}

%fontsize14 {
    font-size: 14px;
    line-height: 19px;
}

@include media-breakpoint-down(lg){
    %fontsize48 {
        font-size: 32px;
        line-height: 38px;
    }
    %fontsize40 {
        font-size: 36px;
        line-height: 42px;
    }
    %fontsize36 {
        font-size: 28px;
        line-height: 32px;
    }

}

@include media-breakpoint-between(md,lg){
    %fontsize48 {
        font-size: 30px;
        line-height: 36px;
    }
    %fontsize36 {
        font-size: 24px;
        line-height: 30px;
    }
    %fontsize30 {
        font-size: 20px;
        line-height: 28px;
    }
    %fontsize20 {
        font-size: 18px;
        line-height: 24px;
    }
}

@include media-breakpoint-only(md){
    %fontsize24 {
        font-size: 20px;
        line-height: 26px;
    }
    %fontsize18 {
        font-size:18px;
        line-height: 26px;
    }
    %fontsize16 {
        font-size: 14px;
        line-height: 23px;
    }
}

@include media-breakpoint-down(md){
    %fontsize48 {
        font-size: 30px;
        line-height: 36px;
    }
    %fontsize40 {
        font-size: 25px;
        line-height: 28px;
    }
    %fontsize36 {
        font-size: 24px;
        line-height: 30px;
    }
    %fontsize30 {
        font-size: 20px;
        line-height: 24px;
    }
    %fontsize20 {
        font-size: 18px;
        line-height: 26px;
    }
}

@include media-breakpoint-down(sm){
    %fontsize48 {
        font-size: 24px;
        line-height: 32px;
    }
    %fontsize40 {
        font-size: 20px;
        line-height: 24px;
    }
    %fontsize30 {
        font-size: 18px;
        line-height: 21px;
    }
    %fontsize24 {
        font-size: 18px;
        line-height: 28px;
    }   
    %fontsize20 {
        font-size: 15px;
        line-height: 20px;
    }
    %fontsize18 {
        font-size: 15px;
        line-height: 23px;
    }
    %fontsize16 {
        font-size: 14px;
        line-height: 20px;
    }     
}
@include media-breakpoint-down(xs){
    %fontsize16 {
        font-size: 13px;
        line-height: 18px;
    }     
}
