/*******************************************************************************
* Full Image Elements
*******************************************************************************/
.full-machine-element {
    padding-top: 130px;
    padding-bottom: 30px;
    margin: 0 auto;
    max-width: 1600px;
    position: relative;
}
.inner-main-images{
    margin-top: 100px;        
    margin-bottom: 0;
    margin-left: 74px;
    position: relative;
    
}
.inner-image{    
    .era-pro-img{        
        left: 19.5%;
        top: -7%;
    }
    .era-tec-img{        
        right: 34.5%;
        top: -1%;
    }
    .era-evo-img{
        right: 18%;
        top: 10%;
    }
    img{
        width: 116px;
        position: absolute;
    }
}


@media (max-width:1400px){
    .inner-image{
        .era-pro-img {
            left: 19.5%;
            top: -11%;
        }
        .era-tec-img {
            right: 30%;
            top: -2%;
        }
        .era-evo-img {
            right: 13%;
            top: 8%;
        }
    }
}

@include media-breakpoint-down(lg){
    .full-machine-element {
        padding: 100px 0;
    }
    .inner-image{
        img{
            width: 100px;
        }
        .era-pro-img {
            left: 18.5%;
            top: -9%;
        }
        .era-tec-img {
            right: 30%;
            top: -4%;
        }
        .era-evo-img {
            right: 13%;
            top: 9%;
        }
    }
}
@include media-breakpoint-down(md){
    .inner-main-images{
        margin-left: 0;
    }
    .full-machine-element {
        padding-top: 50px;        
        padding-bottom: 10px;
    }
    .inner-image{
        img{
            width: 90px;
        }
        .era-pro-img {
            left: 20.5%;
            top: -13%;
        }
        .era-tec-img {
            right: 30%;
            top: -4%;
        }
        .era-evo-img {
            right: 12%;
            top: 6%;
        }
    }
}
@include media-breakpoint-down(sm){    
    .inner-image{       
        img {
            width: 65px;
        }
    }
    .inner-main-images{        
        margin: 0 auto;            
        margin-top: 100px;
    }
}
@include media-breakpoint-down(xs){
    .inner-image{      
        .era-pro-img {
            left: 17.5%;
            top: -17%;
        }
        .era-tec-img {
            right: 27%;
            top: -8%;
        }
        .era-evo-img {
            right: 11%;
            top: 6%;
        }
        img {
            width: 52px;
        }
    }
    .inner-main-images{        
        margin: 0 auto;            
        margin-top: 50px;
    }
}