/*******************************************************************************
* Two-Col Elements
*******************************************************************************/
.two-column-element{
    padding-top: 20px;
    padding-bottom: 50px;
}
.era-column-block{    
    position:relative; 
    .column-image{
        padding-top: 12px;
        margin-left: -25px;
    }
}   
.era-column-content{
    padding-top: 52px;
    h4{
        margin-bottom: 37px;
    }
}
.column-logo{
    position: absolute;
    right: 3.5%;
    top: 33%;
    height: auto;
    width: auto;
    img{
       width: 423px;
    }    
}
.inner-width-element{
    .column-image{
        padding-top: 17px;
        margin-left: 132px;
    }
    .column-logo{
        right: 14.5%;
        top: 32.5%;
    }
    .era-column-block{
        padding-top: 0;
    }
    .era-column-content{
        margin-top: 0;
        padding-top: 51px;
    }
}
.full-image-block{
    &.inner-width-element{
        .column-image{
            padding-top: 17px;
            margin-left: 132px;
        }
        .column-logo{
            right: 14.5%;
            top: 32.5%;
        }
        .era-column-block{
            padding-top: 0;
        }
        .era-column-content{
            margin-top: 0;
            padding-top: 51px;
        }
    }
    .era-column-block{
        padding-top: 83px;
        max-width: 1600px;
        margin:0 auto;
        .column-image{
            img{
                &:first-child{
                    margin-left: 31px;
                    display: block;
                }
                &:last-child{
                    display: none;
                }
            }
        }        
    }
    .column-logo{
        right: 14.5%;
        top: 36.5%;
    }
    .era-column-content{
        padding-top: 0;
        margin-top: -70px;
        z-index: 9;
        position: relative;
    }
}

@media (max-width:1400px){
    .full-image-block{
        &.inner-width-element{
            .column-image{                
                margin-left: 0px;
            }                                    
        }
    }
    .column-logo{ 
        right: 10%;        
        img{
           width: 350px;
        }
    }    
    .full-image-block{
        .column-image {
            max-width: 90%;
        }
    }
    .column-image {
        max-width: 60%;
    }
}
@include media-breakpoint-down(lg){
    .full-image-block{
        &.inner-width-element{
            .column-image{
                max-width: 550px;
            }                
        }
    }
    .era-column-block{
        .column-image{
            margin-left: 0;
        }
    }
    .column-logo{     
        img{
           width: 300px;
        }
    }        
}
@include media-breakpoint-down(md){    
    .column-logo {
        left: 60%;
        top: 35%;
        right: auto;
        img{
            width: 260px;
        }
    }
    .full-image-block{  
        &.inner-width-element{
            .column-image{
                max-width: 450px;
            }                
        }
        .era-column-content{
            margin-top: -30px;
        }
    }
}
@include media-breakpoint-down(sm){    
    .era-column-block{            
        .column-image{            
            margin-left: 0;
        }
    } 
    .full-image-block{       
        &.inner-width-element{
            .column-image{
                max-width: 350px;
            }                
        }
        .era-column-block{
            padding-top: 30px;
        }
        .era-column-content{
            margin-top: 0;
        }
    }
    .column-logo {        
        top: 30%;
        right: auto;
        img{
            width: 200px;
        }
    }
}
@include media-breakpoint-down(xs){    
    .full-image-block{
        &.inner-width-element{
            .column-image{
                max-width: 90%;
                margin:0 auto;
            }   
             .column-logo {        
                top: auto;            
                right: auto;
            }
        }
        .era-column-block{
            .column-image{
                img{
                    &:first-child{
                        display: none;
                    }
                    &:last-child{
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
        .column-logo {        
            top: auto;            
            right: auto;
        }
    }
    .column-logo {        
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        margin: 0 auto;
        text-align: center;
        img{
            width: 220px;
            margin-bottom: 40px;
        }
    }
    .full-image-block{
        .column-image {
            max-width: 100%;
        }
    }
    .column-image {
        max-width: 100%;
        margin: 0 auto;
    }
}