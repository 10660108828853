/*******************************************************************************
* PAGE HEADER
*******************************************************************************/
header.page{
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: $black;
    @include inline-block;
    width: 100%;
    border-bottom: 1px solid #323232;
}
.header-main{
    padding: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    position: relative;
}
.navigation {
    color: $brand-color;
    nav{
        ul{
            li{
                a{
                    color: $brand-color-gray;
                    font-family: $font-secondary;
                    @include inline-block;
                    @extend %fontsize20;
                    img{
                        height: 30px;
                        &.language-icon {
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
    .dropdown-toggle::after{
        display: none;
    }
    .mobile-language-menu{
        display: none;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 24px;
        margin-left: -2px;
        @include horizontal-align();
        li{
            @include inline-block;
            position: relative;
            margin: 0 12px;
            &:after{
                content: '';
                background-color: $brand-color-gray;
                position: absolute;
                top: 5px;
                right: -14px;
                height: 18px;
                width: 1px;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            a{
                color: $brand-color-gray;
                font-family: $font-secondary;
                @include inline-block;
                @extend %fontsize20;
            }
        }
    }
}
.navigation ul,
.navigation li{
    margin: 0;
    padding: 0;
    list-style: none;
}
.navigation li:before{
    display: none;
}

.divLanguageMenu {
    .dropdown-menu {
        background-color: $brand-dark-gray;
        padding: 2px 0 5px;
        margin: 10px 0 0;
        border: 1px solid $brand-color-separator;
        border-radius: 0;
        li {
            a {
                position: relative;
                padding: 4px 20px 5px;
                margin: 3px 0;
                width: 100%;
                @extend %fontsize14;
                @include inline-block;
                font-family: $font-primary;
                &:after{
                    content: '';
                    border-right: 2px solid $brand-color-gray;
                    border-bottom: 2px solid $brand-color-gray;
                    height: 9px;
                    width: 9px;
                    position: absolute;
                    top: 9px;
                    right: 15px;
                    @include rotate(-45deg);
                    @include transition(all, 0.2s, ease, 0s);
                }
                &:hover{
                    background-color: $brand-color;
                    color: $brand-color-primary;
                    &:after{
                        content: '';
                        border-right: 2px solid $brand-color-primary;
                        border-bottom: 2px solid $brand-color-primary;
                        right: 12px;
                    }
                }
                img{
                    height: 14px;
                    padding-right: 10px;
                    @include inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}
.brand-logo {
    @include inline-block;
    a{
        @include inline-block;
    }
    img{
        max-width: 234px;
    }
}
.menu-trigger {
    position: absolute;
    left: 0;
    top: 42px;
    cursor: pointer;
    height: 21px;
    width: 28px;
    padding: 0;
    opacity: 1;
    @include transition(all, 0.2s, ease, 0s);
    .bars {
        background-color: $brand-color-gray;
        border-radius: 0;
        height: 3px;
        margin-bottom: 6px;
        width: 100%;
        @include transition(all, 0.2s, ease, 0s);
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.menu--open {
    .bars {
        &:first-child {
            -webkit-transform: translateY(10px) rotate(136deg);
            transform: translateY(10px) rotate(136deg);
        }
        &:last-child {
            -webkit-transform: translateY(-8px) rotate(-136deg);
            transform: translateY(-8px) rotate(-136deg);
        }
        &:nth-child(2) {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
    }
}
@include media-breakpoint-up(lg){
    .header-main {
        @include justify-content(space-between);
    }
    .menu-trigger {
        display: none;
    }
    .navigation {
        nav{
            > ul{
                height: 99px;
                @include flexbox;
                @include align-items(center);
                > li{
                    position: relative;
                    padding: 10px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-only(lg){
    .header-main {
        padding: 0 5px 0 0;
    }
    .brand-logo img {
        max-width: 140px;
    }
    .navigation nav > ul > li {
        padding: 30px 10px;
    }
    .navigation {
        padding: 0 10px 0 5px;
        nav {
            ul {
                li {
                    a {
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    .header-main{
        padding: 11px 0;
        min-height: 64px;
    }
    .brand-logo {
        img {
            max-width: 158px;
        }
    }
    .divLanguageMenu{
        display: none;
    }
    .menu--open{
        overflow: hidden;
        .navigation {
            opacity: 1;
            pointer-events: auto;
        }
    }
    .navigation {
        background-color: $black;
        left: 0;
        margin: 0;
        overflow: auto;
        padding: 0;
        position: fixed;
        top: 65px;
        width: 100%;
        z-index: 999;
        font-size: 15px;
        opacity: 0;
        pointer-events: none;
        @include transition(opacity, 0.2s, ease, 0s);
        -webkit-box-shadow: inset 0px 5px 5px -4px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: inset 0px 5px 5px -4px rgba(0, 0, 0, 0.15);
        box-shadow: inset 0px 5px 5px -4px rgba(0, 0, 0, 0.15);
        nav{
            ul{
                padding: 9px 15px;
                position: relative;
                width: 100%;
                li{
                    position: relative;
                    width: 100%;
                    text-align: center;
                    padding: 18px 19px 19px;
                    @include inline-block;
                    &.visible-mobile{
                        display: block;
                    }
                    a{
                        font-size: 20px;
                        line-height: 22px; 
                    }
                }
            }
        }
        .mobile-language-menu{
            display: block
        }
    }
    nav {
        overflow: auto;
        width: 100%;
        > ul{
            > li{
                > a{
                    display: block;

                    position: relative;
                    z-index: 99;
                }
            }
        }
    }
    .menu-trigger {
        top: 24px;
        height: 20px;
        width: 24px;
        z-index: 3;
        .bars {
            height: 2px;
            margin-bottom: 6px;
        }
    }
    .menu--open {
        .bars{
            &:first-child {
                -webkit-transform: translateY(8px) rotate(136deg);
                transform: translateY(8px) rotate(136deg);
            }
            &:last-child {
                -webkit-transform: translateY(-8px) rotate(-136deg);
                transform: translateY(-8px) rotate(-136deg);
            }
        }
    }
}
@include media-breakpoint-between(md,lg){
    .navigation{
        nav{
            > ul {
                > li{
                    padding: 30px 8px;
                 }
            }
        }
    }
}
@include media-breakpoint-only(md){
    .navigation nav ul li {
        padding: 25px 30px 25px 15px;
    }
}
@media screen and (max-height: 510px){
     .navigation{
        .mobile-language-menu{
            position: relative;
        }
    }
}
@media (max-width: 767.98px) and (orientation: landscape) {
    .navigation{
        .mobile-language-menu{
            position: relative;
        }
    }
}
@include media-breakpoint-only(xs){
    .navigation{
        .mobile-language-menu{
            li{
                a{
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
}
